"use strict";
console.info('Hello world');
AOS.init({once: true});
AOS.init({
  once: true,
  disable: function() {
    var maxWidth = 800;
    return window.innerWidth < maxWidth;}
});

var portFolioLength = -1;
var root = document.documentElement;
const body = document.querySelector('body'),
      portfolio = document.querySelector('.portfolio'),
      portfolioIndic = document.querySelector('.portfolio-indicator'),
      contactBtn = document.querySelectorAll('#openContact'),
      timelineBtn = document.querySelector('.timeline-el-btn'),
      menuEl = document.querySelectorAll('.menu-el'),
      contactDiv = document.querySelector('.contact-div'),
      menuBtn = document.querySelector('.btn-menu'),
      btns = document.querySelectorAll('.btn'),
      closeContactBtn = document.getElementById('closeContact'),
      header = document.querySelector('.header');


// Menu opening
menuBtn.addEventListener('click', (e) => {
  body.classList.toggle('menu--open');
});

menuEl.forEach(element => {
  element.addEventListener('click', (e) => {
    body.classList.remove('menu--open');
  });
});

// Buttons effect
btns.forEach(btn => {
  btn.addEventListener('click', (e) => {
    let target = e.currentTarget;
    let hover = document.createElement('span');
    hover.classList.add('hover');

    let offest = target.getBoundingClientRect();
    let y = e.clientY - offest.top;
    let x = e.clientX - offest.left;

    hover.style.top = y + "px";
    hover.style.left = x + "px";

    target.appendChild(hover);


    hover.addEventListener('animationend', (e) => {
      hover.remove();
    });
  });
});

// Header styling
document.addEventListener('scroll', (e) => {
    if(window.scrollY > 50) {
        if(header.classList.contains('scrolled') == false) {
            header.classList.add('scrolled');
        }
    }else{
        if(header.classList.contains('scrolled')) {
            header.classList.remove('scrolled');
        }
    }
});

// Contact form opening
contactBtn.forEach(element => {
  element.addEventListener('click', (e) => {
    body.classList.add('contactOpening');
    contactDiv.addEventListener('animationend', (e) => {
      body.classList.remove('contactOpening');
      body.classList.add('contactFormOpen');
    });
  });
});

closeContactBtn.addEventListener('click', (e) => {
  body.classList.add('contactClosing');
  contactDiv.addEventListener('animationend', (e) => {
    body.classList.remove('contactClosing');
    body.classList.remove('contactFormOpen');
  });
});

function displayDescription(data) {

    projectDescription.innerHTML = "";
    btnGroup.innerHTML = "";
  
    createImg(data);
    createText(data);
    createBtn(data);
  }

fetch("./assets/portfolio.json")
.then(function (response) {
    return response.json();
})

.then(function (data) {
    JSON.stringify(data);
    data.forEach((data) => {

        root.style.setProperty('--c-'+data.id, data.color);
        
        let li = document.createElement('li');
        li.classList.add('portfolio-el');
        li.setAttribute('id', data.id);

        let dot = document.createElement('li');
        dot.classList.add('portfolio-indicator-el');
        portfolioIndic.appendChild(dot);

        if(data.id == "0") {
          li.classList.add('actual');
          dot.classList.add('actual');
        }
        
        // Div du contenu
        let div = document.createElement('div');
        div.classList.add('portfolio-el-div');

        // Div de l'image
        let divb = document.createElement('div');
        divb.classList.add('portfolio-el-image');
        divb.classList.add('pretty-bg');
        divb.style.backgroundColor = data.color;

        // Ombre de l'image
        let shadow = document.createElement('div');
        shadow.classList.add('portfolio-el-image-shadow');

        // Contenu
        let h3 = document.createElement('h3');
        let span = document.createElement('span');
        let p = document.createElement('p');
        h3.innerHTML = data.name;
        span.innerHTML = data.type;
        p.innerHTML = data.description;

        // Bouton
        var button = document.createElement('a');
        button.setAttribute('href', data.link);
        button.setAttribute('target', '_blank');
        button.classList.add('btn');
        button.classList.add('btn-picto');
        button.style.backgroundColor = data.color;
        if(data.hasOwnProperty('customBtn')){
          button.innerHTML = "<i class='fas fa-globe'></i>" + data.customBtn;
        }else{
          button.innerHTML = "<i class='fas fa-globe'></i>Visiter le site";
        }

        // Image
        let slug = data.name.replace(" ", '-').toLowerCase();
        let img = document.createElement('img');
        img.setAttribute("alt", "Aperçu");
        img.setAttribute("srcset", "assets/portfolio/" + slug +".png, assets/portfolio/" + slug +"@2x.png 2x");
        img.classList.add('portfolio-el-image-img');

        // AppendChild des éléments
        div.appendChild(h3);
        div.appendChild(span);
        div.appendChild(p);
        div.appendChild(button);
        divb.appendChild(img);
        divb.appendChild(shadow);
        li.appendChild(div);
        li.appendChild(divb);
        portfolio.appendChild(li);

        portFolioLength++;
        root.style.setProperty('--workN', portFolioLength);
    });
});


const portfolioControls = [
  document.querySelector('.portfolioContainer-buttons-next'), 
  document.querySelector('.portfolioContainer-buttons-previous')
];

for (let i = 0; i < portfolioControls.length; i++) {
  portfolioControls[i].addEventListener('click', (e) => {

    // si on regarde le suivant
    if (i == 0) {
      slide('left');
    }else{
      slide('right');
    }
  });
}
portfolio.setAttribute('color', 0);



// Swipe portfolio
// DETECT A SWIPE WITH JAVASCRIPT
// Source: https://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android
// Réponse de givanse (https://stackoverflow.com/users/7852/givanse) sur stack overflow
portfolio.addEventListener('touchstart', handleTouchStart, false);        
//portfolio.addEventListener('touchmove', handleTouchMove, false);
portfolio.addEventListener('touchend', handleTouchEnd, false);

var start;                                                        
var end;                                                        


function handleTouchStart(evt) {
    start = evt.changedTouches[0].screenX;
};                                                

//function handleTouchMove(evt) {
    //var moove = evt.changedTouches[0].clientX - (window.innerWidth/2);
    //let actualPortfolioEl = document.querySelector('.portfolio-el.actual');
    //actualPortfolioEl.style.transform = "translateX("+moove+"px)";
//};

function handleTouchEnd(evt) {
  //let actualPortfolioEl = document.querySelector('.portfolio-el.actual');
  //actualPortfolioEl.style.transform = "translateX(0px)";
  end = evt.changedTouches[0].screenX;
  if ( start + 70 < end ) {
    slide('right');
  } else if ( start - 70 > end ) {
    slide('left');
  }
};


function slide(direction) {
  let actualDot = document.querySelector('.portfolio-indicator-el.actual');
  let actualEl = document.querySelector('.portfolio-el.actual');
  let actualId = Number(actualEl.getAttribute('id'));
  let newEl;
  let nextDot;
  let anim;
  let newId;
  if (direction == "left") {
    anim = "right";
    newId = (actualId + 1) > portFolioLength ? 0 : actualId + 1;
    nextDot = document.querySelector('.portfolio-indicator-el:nth-child('+ (newId + 1) +')');
    newEl = document.getElementById(newId);
  } else {
    anim = "left";
    newId = (actualId - 1) < 0 ? portFolioLength : actualId - 1;
    nextDot = document.querySelector('.portfolio-indicator-el:nth-child('+ (newId + 1) +')');
    newEl = document.getElementById(newId);
  }

  actualEl.classList.add('hiding-' + anim);
  actualEl.addEventListener('animationend', (e) => {
    actualEl.classList.remove('actual');
    actualEl.classList.remove('hiding-' + anim);
  });
  
  newEl.classList.add('showing-' + anim);
  newEl.addEventListener('animationend', (e) => {
    newEl.classList.add('actual');
    newEl.classList.remove('showing-' + anim);
  });

  portfolio.setAttribute('color', newId);

  actualDot.classList.remove('actual');
  nextDot.classList.add('actual');
  portfolio.setAttribute('color', newId);
  console.log(actualId, newId);
}


// CONTACT
const sendMessage = document.getElementById('feedback'),
      sendBtn = document.querySelector('.form');

sendBtn.addEventListener('submit', (e) => {
  e.preventDefault;
  sendMessage.classList.add('anim');
  sendMessage.addEventListener('animationend', (e) => {
    sendMessage.classList.remove('anim');
  });
});

$(document).ready(function () {
  $("#submitBtn").click(function (event) {
    var name = document.getElementById('name');
    var email = document.getElementById('email');
    var message = document.getElementById('message');
    $('#feedback').addClass('anim');

    $('#feedback').one('webkitAnimationEnd oanimationend oAnimationEnd msAnimationEnd animationend',   
    function(e) {
      $('#feedback').removeClass('anim');
      $('#feedback').empty();
    });


    var dataString = {
      "name": name.value,
      "email": email.value,
      "message": message.value
    };
    $.ajax({
      type: "post",
      url: "send-mail.php",
      data: dataString,
      success: function success(html) {
        $('#feedback').html(html);
        $('.omsc-accordion .omsc-toggle')

        fieldCheck(name);
        fieldCheck(message);
        fieldCheck(email);
      }
    });
    event.preventDefault();
  });
});

// Modifie le formulaire sans refresh
function fieldCheck(field) {
  if ( field.value == "" ) {
    $(field).addClass('invalid');
  }else {
    $(field).removeClass('invalid');
  }
}


// Copy phone number
function copyPhoneNumber() {
  var textArea = document.createElement("textarea");
  textArea.value = "+32 476 058 066";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    textArea.style.display = 'none';
    var msg = successful ? "Numéro de téléphone copié." : "Le numéro n'a pas pu être copié.";
    $('#feedback').empty();
    $('#feedback').addClass('anim');
    
    $('#feedback').html("<span class='valid'>"+msg+"</span>");

    $('#feedback').one('webkitAnimationEnd oanimationend oAnimationEnd msAnimationEnd animationend',   
    function(e) {
      $('#feedback').removeClass('anim');
    });

  } catch (err) {
    console.log('ERREUR - impossible de copier le numéro de téléphone');
  }
}

document.querySelector("#phoneNumber").addEventListener("click", (copyPhoneNumber));